@highlight_color: rgba(255,255,255,0.1);
@shadow_color: rgba(0,0,0,1);

.card_shadow(
  @border_color: #232323
) {
// border-right: 5px solid @border_color;
// border-bottom: 5px solid @border_color;
  box-shadow: 
    2px 2px 0 2px @border_color,
    5px 5px 10px 5px @shadow_color,
    inset 0 0 1px 1px @highlight_color,
    inset 10px 10px 40px @highlight_color;
}

