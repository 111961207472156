.graph_index {
  margin-top: 60px;
  padding: 20px 30px;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  list-style: none;

  &__link {
    display: block;
    margin: 20px 5px;
  }
}
