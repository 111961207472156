@import '_font.less';
@import '_card.less';
@import '_colors.less';

._button(
  @button_color,
  @font_color: @color_black,
) {
  border: none;
  padding: 8px 13px;
  font-size: 1.1em;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  text-decoration: none;

  .font_headers_med;

  ._button_color(@button_color, @font_color)
}

._button_color(
  @button_color,
  @font_color: @color_black,
) {
  color: @font_color;
  background-color: @button_color;
  .card_shadow(darken(@button_color, 30%));
  transition: box-shadow .2s ease, background-color .2s ease;

  &:hover {
    background-color: darken(@button_color, 20%);
    .card_shadow(darken(@button_color, 50%));
  }
}
