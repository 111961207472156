.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  padding: 30px; 
  left: 0;
  background: rgba(10,3,20,0.2);
  justify-content: center;
  align-items: center;
}
