@import '_button.less';
@import '_colors.less';
@import '_font.less';

@import 'font.css';

/**
 * BASICS
 */
html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: @color_dark;
  color: @color_white; 
  font-family: @font;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.app {
  main {
    position: relative;
  }
}

@import 'box.less';
@import 'buttons.less';
@import 'icons.less';
@import 'field.less';

