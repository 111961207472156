@font: 'Space Mono', monospace;

.font_headers_reg {
  font-family: @font;
  font-weight: 400;
}

.font_headers_med {
  font-family: @font;
  font-weight: 700;
}

.font_text {
  font-family: @font;
}
