@icon_size: 25px;

.icon_image_properties (@src_url) {
    background-image: url(@src_url);
    background-size: @icon_size @icon_size;
    background-position: center center;
    background-repeat: no-repeat;
}

.icon {
  display: inline-block;

  width: @icon_size;
  height: @icon_size;
  overflow: hidden;
  text-indent: -1000px;

  vertical-align: middle;

  &--exit {
    .icon_image_properties('../assets/icons/icon-exit.svg');
  }
  &--loading{
    .icon_image_properties('../assets/icons/icon-loading.svg');
    animation-name: loading;
    animation-duration: 4s;
  }
  &--edit{
    .icon_image_properties('../assets/icons/icon-edit.svg');
  }
  &--text{
    .icon_image_properties('../assets/icons/icon-text.svg');
  }
  &--goto{
    .icon_image_properties('../assets/icons/icon-goto.svg');
  }
}

@keyframes rotateIcon{
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
