@import '_colors.less';
@import '_font.less';


.login_form{
  display: flex;
  flex-direction: column;
  width: 100%;

  &__section {
    margin: 20px 0;
    width: 100%;
    position: relative;
    display: flex;
    align-content: center;
    justify-content: space-around;


    label {
      display: block;
      flex: 1;
      margin-right: 30px;
    }

  }
}
