.search_bar {
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;

  &:hover &__results {
    display: flex !important;
  }
  &:hover &__results--noContent {
    display: none !important;
  }

  &__results {
    position: absolute !important;
    width: 100%;
    left: 0;
    top: 0;
    flex-direction: row !important;
    flex-wrap: wrap;
    &--hidden {
      display: none !important;
    }
    &--show {
      display: flex;
    }
  }
}

.result_item {
  display: flex;
  margin: 10px;
}

.field--search_bar {
  width: 360px;
  margin: auto 20px;
}
