@font-face {
  font-family: 'Space Mono';
  font-weight: 400;
  src: local('Space Mono'), url(../assets/font/SpaceMono-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Space Mono';
  font-weight: 700;
  src: local('Space Mono'), url(../assets/font/SpaceMono-Bold.ttf) format('truetype');
}
