@import '_colors.less';

.text_view {
  color: @color_white;
  

  & a {
    color: @color_blue;
    &:hover, &:active, &:visited {
      color: @color_blue;
    }
  }

  img {
    max-width: 100%;
    max-height: 50vh;
  }

  ul {
    padding-left: 20px;
  }

}
