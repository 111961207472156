@import '_overlay.less';

.node_view{
  width: 100vw;
  height: 100vh;
  display: flex;

  &__graph {
    flex: 1;
  }

  &__dialog {
    .overlay;
    pointer-events: none;

    > * {
      pointer-events: auto;
    }

    z-index: 10;

    &--open {
      display: flex;
    }

    &--closed {
      display: none;
    }
  }
}


