@import '_card.less';
@import '_colors.less';

.head {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  
  height: 60px;
  width: 100vw;

  padding: 5px 20px;
  


  background: fade(@color_dark, (0.9*100));
  .card_shadow(darken(@color_dark, 30%));

}

.button--head {
  margin: auto 10px;
}
