@import '_button.less';
@import '_colors.less';
@import '_card.less';

.button {
  ._button(@color_green);

  &--small_icon {
    border-radius: 25%;
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0 5px;
  }

  &.button--red {
    ._button_color(@color_red);
  }

  &.button--blue {
    ._button_color(@color_blue);
  }

  &.button--yellow{
    ._button_color(@color_yellow);
  }
}


