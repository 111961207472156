@import './_colors.less';
@import './_card.less';

.message_display {
  position: fixed;
  z-index: 20;
  bottom: 0;
  right: 0;
  padding: 10px 20px;
  font-size: 1.1rem;
}

.message {

  color: @color_black;

  padding: 20px 15px;
  border-radius: 5px;
  white-space: pre-line;

  .card_shadow(@color_blue);
  
  background: @color_blue;
  margin: 5px 0;

  animation: reveal_message .2s ease-in-out;

  &--error {
    background: @color_red;
    .card_shadow(@color_red);
  }

  &--success {
    background: @color_green;
    .card_shadow(@color_red);
  }
}

@keyframes reveal_message {
  from { 
    opacity: 0;
  }
  to { 
    opacity: 1;
  }
}
