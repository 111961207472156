.edit_form {
  &__section {
    margin: 20px 0;
    width: 100%;
    position: relative;
  }
}

.code_editor__textarea {
  caret-color: #fff;
}
