@import '_card.less';
@import '_colors.less';

.box {
  position: relative;
  min-width: 360px;
  max-height: calc(~"100vh - 60px");
  margin: 10px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 25px;
  background: fade(@color_dark, (0.9*100));
  padding: 30px;
  padding-top: 40px;

  .card_shadow(darken(@color_dark, 30%));

  &__toolbar {
    position: absolute;
    top: 0px;
    right: 20px;
    display: flex;
    justify-content: flex-end;
  }

  &__content {
    width: 100%;
    max-height: calc(~"100vh - 130px");
    overflow: auto;
  }
}
